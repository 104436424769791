import { useMutation, useQueryClient } from 'react-query';
import { Http } from '../services/Http';
import { ACTIVITY_SUBTYPE, ACTIVITY_TYPE, CompanyActivity, CompanyActivityValue } from '../types';
import { useToastMessage } from '../hooks/useToastMessage';

export const COMPANY_ACTIVITY = 'companyActivity';

export type CompanyActivityPayload = {
  type: ACTIVITY_TYPE;
  company: number;
  user: number;
  subtype?: ACTIVITY_SUBTYPE;
  value: CompanyActivityValue;
};

async function createActivity(payload: CompanyActivityPayload): Promise<CompanyActivity> {
  const { data } = await Http.axios.post<CompanyActivityPayload, CompanyActivity>(
    `/company-activity-portal`,
    payload
  );
  return data;
}

export function useCreateCompanyActivity(companyId?: number) {
  const queryClient = useQueryClient();
  const { pushErrorToast } = useToastMessage();

  return useMutation(createActivity, {
    onError: (error) => {
      console.error({ error });
      pushErrorToast({ message: 'Failed to create company activity' });
    },
    onSuccess: () => {
      queryClient.invalidateQueries([`${COMPANY_ACTIVITY}-${companyId}`, companyId]);
    },
  });
}
